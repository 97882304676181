var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"column":"","align-center":"","justify-center":""}},[_c('v-layout',{staticClass:"hidden-sm-and-down",attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"height":"50px"},attrs:{"d-flex":"","xs12":"","justify-center":"","align-center":""}},[_vm._v("   ")])],1),_c('v-carousel',{attrs:{"cycle":_vm.cycle,"height":_vm.carouselHeight,"hide-delimiter-background":"","hide-delimiters":_vm.controlsVisible,"show-arrows":!_vm.controlsVisible,"delimiter-icon":"mdi-circle-medium"}},_vm._l((_vm.carouselImages),function(image,index){return _c('v-carousel-item',{key:index},[_c('img',{style:({ height: _vm.carouselHeight + 'px', width: '100%' }),attrs:{"src":image.url},on:{"click":function($event){_vm.cycle=!_vm.cycle}}}),_c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"slide-text",style:({ top: _vm.textTopPos(image) - image.underlayMargin / 2 + 'px', 
          left: _vm.textLeftPos(image) - image.underlayMargin + 'px', 
          height: image.underlayHeight * _vm.divScale + 'px',
          width: image.underlayWidth * _vm.divScale + 'px',
          backgroundColor: image.underlayColor,
          opacity: image.underlayOpacity,
          borderRadius: image.underlayMargin * _vm.divScale / 2 + 'px' })}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"slide-text",style:({ top: _vm.textTopPos(image) - image.underlayMargin / 2 + 'px', 
          left: _vm.textLeftPos(image) - image.underlayMargin + 'px', 
          height: image.underlayHeight + 'px',
          width: image.underlayWidth + 'px',
          backgroundColor: image.underlayColor,
          opacity: image.underlayOpacity,
          borderRadius: image.underlayMargin / 2 + 'px' })}):_vm._e(),_c('div',{staticClass:"slide-text",style:({ top: _vm.textTopPos(image) + 'px', 
          left: _vm.textLeftPos(image) + 'px', 
          color: image.textColor, 
          textShadow: '1px 1px 5px rgba(' + image.textShadow + ')'})},[_c('div',{class:{'slide-title-sm': _vm.$vuetify.breakpoint.smAndDown, 'slide-title': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(_vm._s(image.line1))]),_c('div',{class:{'slide-sub-title-sm': _vm.$vuetify.breakpoint.smAndDown, 'slide-sub-title': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(_vm._s(image.line2))]),_c('div',{class:{'slide-sub-title-sm': _vm.$vuetify.breakpoint.smAndDown, 'slide-sub-title': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(_vm._s(image.line3))])])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }