export default {
  property: {
    name: "Pynchon Towne Houses",
    phone: "(413) 786-3174",
    email: "info@pynchon-towne-houses.com",
    web: "https://pynchon-towne-houses.com",
    image: "",
    address: {
      street: "350 Meadow St",
      city: "Agawam",
      state: "MA",
      postalCode: "01001",
      lat: 42.079730,
      lng: -72.599570,
      googlePlaceID:
        "ChIJG8mc6__h5okRBeMFm5iYjXo"
    },
    tenantsLoginUrl:
      "https://knightassociates.appfolio.com/connect/users/sign_in",
    titleKeywords: "Apartments for rent in Agawam, MA"
  },
  toolbarNavigation: [
    {
      heading: true,
      title: "",
      routeName: "PropertyDetails",
      action: "link"
    },
    { spacer: true },
    {
      hiddenXsOnlyButton: true,
      title: "",
      path: "",
      action: "phone"
    },
    {
      hiddenXsOnlyIcon: true,
      path: "",
      icon: "mdi-email",
      action: "email"
    },
    {
      hiddenSmAndDown: true,
      title: "Contact Us",
      routeName: "ContactUs",
      action: "contact"
    },
    {
      hiddenSmAndDown: true,
      title: "Tenants Login",
      path: "",
      action: "url"
    }
  ],
  sideNavigation: [
    {
      heading: true,
      title: "",
      image: ""
    },
    {
      title: "",
      path: "",
      icon: "mdi-phone",
      action: "phone"
    },
    { divider: true },
    {
      title: "Property Details",
      routeName: "PropertyDetails",
      icon: "mdi-domain",
      action: "link"
    },
    {
      title: "Virtual Tour",
      routeName: "VirtualTour",
      icon: "mdi-rotate-3d",
      action: "link"
    },
    {
      title: "Neighborhood",
      routeName: "Neighborhood",
      icon: "mdi-account-multiple",
      action: "link"
    },
    {
      title: "Apply Here",
      routeName: "ApplyHere",
      icon: "mdi-calendar-check",
      action: "link"
    },
    {
      title: "Contact Us",
      routeName: "ContactUs",
      icon: "mdi-face-agent",
      action: "contact"
    },
    { divider: true },
    {
      title: "",
      path: "",
      icon: "mdi-email",
      action: "email"
    },
    {
      title: "",
      subTitle: "",
      path: "",
      icon: "mdi-map-marker",
      action: "map",
      twoLine: "true"
    },
    { divider: true },
    {
      title: "Tenants Login",
      path: "",
      icon: "mdi-account",
      action: "url"
    }
  ],
  sideNavigationImages: [],
  pageNavigation: [
    {
      title: "Property Details",
      route: "property-details",
      visible: true
    },
    {
      title: "Virtual Tour",
      route: "virtual-tour",
      visible: true
    },
    {
      title: "Neighborhood",
      route: "neighborhood",
      visible: true
    },
    {
      title: "Apply Here",
      route: "apply-here",
      visible: true
    },
    {
      title: "Contact Us",
      route: "contact-us",
      visible: false
    }
  ],
  carouselImages: [],
  propertyDetails: "",
  lightboxImages: [],
  floorPlanImages: [],
  amenities: {},
  amenitiesImages: [],
  amenitiesList: [],
  utilities: {},
  utilitiesImages: [],
  utilitiesList: [],
  virtualTour: [
    {
      walkthroughVideo: {
        title: "",
        url: ""
      },
      virtualTour: {
        title: "",
        url: ""
      }
    }
  ],
  virtualTourList: [],
  entertainment: [],
  dining: [],
  groceries: [],
  parksFitness: [],
  hospital: [],
  neighborhoodList: [],
  contactUsDialog: false,
  contactUsLoading: false,
  applyHereLoading: false,
  tvImages: [],
  tvLog: [],
  snackbar: {
    show: false,
    color: "success",
    timeout: 4000,
    message: ""
  },
  authReady: false,
  user: null,
  loginDialogType: false,
  loginDialog: false,
  loginLoading: false,
  toolbarNavigationAdmin: [
    {
      heading: true,
      title: "",
      routeName: "Home",
      action: "link",
      show: true
    },
    { spacer: true, show: true },
    {
      title: "Side Navigation",
      routeName: "SideNavigationAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Property Details",
      routeName: "PropertyDetailsAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Neighborhood",
      routeName: "NeighborhoodAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Virtual Tour",
      routeName: "VirtualTourAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Apply Here",
      routeName: "ApplyHereAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Contact Us",
      routeName: "ContactUsAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "TV",
      routeName: "TVAdmin",
      action: "link",
      logged: true,
      show: true
    },
    {
      title: "Login",
      routeName: "Login",
      action: "login",
      logged: false,
      show: true
    },
    {
      title: "Logoff",
      routeName: "Logoff",
      action: "logoff",
      logged: true,
      show: true
    }
  ],
  applyHereList: [],
  contactUsList: []
};
