import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyCwJxjyP2M4yms5Dl0Ggd1s--D2CuFayhI",
  authDomain: "pynchon-towne-houses.firebaseapp.com",
  databaseURL: "https://pynchon-towne-houses.firebaseio.com",
  projectId: "pynchon-towne-houses",
  storageBucket: "pynchon-towne-houses.appspot.com",
  messagingSenderId: "584033583589",
  appId: "1:584033583589:web:2f9fd92ac88d22f301af0c",
  measurementId: "G-T14ED2YECL"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
